<template>
  <div>
    <template v-if="isShowed('products_list')">
      <template
        v-for="(product, index) in products"
        :key="`product_${index}`"
      >
        <product-card
          :product="product"
          @update-quantity="item => updateBasket(item)"
        />
      </template>
    </template>

    <app-save-button
      v-if="isShowed('text_order') && labelButton"
      class="purchase__button margin__t--12"
      :is-disabled="isPreview || !isApproved"
      @click="handleClickOrder"
    >
      {{ labelButton }}

      <template #icon>
        <fa-icon
          class="icon"
          :icon="['fal', 'basket-shopping-simple']"
          fixed-width
        />
      </template>
    </app-save-button>
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed, reactive } from 'vue'
import { useProductStore } from '@/stores/ProductStore'
import { useI18n } from '@/vendors/i18n'
import { useRouter } from 'vue-router'
import { useBlock } from '@/composables/modules/templates'
import { get as _get, findIndex as _findIndex } from 'lodash'

const ProductCard = defineAsyncComponent(() => import('&/organisms/ProductCard'))
const AppSaveButton = defineAsyncComponent(() => import('&/molecules/AppSaveButton'))

const props = defineProps({ block: { type: Object, required: true } })

const { translate } = useI18n()
const { push } = useRouter()
const { objects, isShowed, getText, isPreview } = useBlock(props.block)
const { getProducts } = useProductStore()
const { products: allProducts } = getProducts({ query: computed(() => ({ project_id: _get(objects.value, 'project.id'), display_on_page: true })), reload: true })

const basket = reactive([])
const products = computed(() => allProducts.value.filter(product => product.display_options !== 'never'))
const labelButton = computed(() => translate(getText('order')))
const procedure = computed(() => _get(objects.value, 'project.procedures', []).find(procedure => procedure.category === 'donation'))
const isApproved = computed(() => _get(objects.value, 'project.status') !== 'need_approval')

const updateBasket = item => {
  const index = _findIndex(basket, i => i.product_id === item.product_id)
  index >= 0 ? basket.splice(index, 1, item) : basket.push(item)
}

const handleClickOrder = () => {
  const query = basket.reduce((a, product, index) => {
    const { product_id, quantity } = product
    return { ...a, [`actions_data[order][order_lines][${index}][product_id]`]: product_id, [`actions_data[order][order_lines][${index}][quantity]`]: quantity }
  }, {})

  push({ name: 'ClientProcedure', params: { procedureSlug: procedure.value.slug }, query })
}
</script>

<style lang="scss" scoped>
.purchase__button {
  width: 100%;
  justify-content: center;
  border-radius: 4px;

  .icon {
    order: 2;
  }
}
</style>
