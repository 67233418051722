import FormConfiguration from '@/classes/procedures/FormConfiguration'
import TwikeyFormConfiguration from '@/classes/procedures/TwikeyFormConfiguration'
import Actions from '@/classes/procedures/Actions'
import Settings from '@/classes/procedures/Settings'
import Transactions from '@/classes/transactions/Transactions'
import Blocks from '@/classes/procedures/Blocks'
import { get as _get, flattenDeep as _flattenDeep } from 'lodash'
import { isPast } from 'date-fns'

export default class Procedure {
  constructor ({ id = null, name = {}, category = null, end_date = null, start_date = null, contributions_count = 0, contributors_count = 0, actions = {}, payment_frequency = null, payment_profile = {}, status = null, slug = null, goal_amount = null, fixed_goal_amount = null, current_amount = 0, transactions, settings = {}, default_values = {}, target_id = null, target_type = null, limit_of_members = null, form_provider = null, success_payment_page_url = {}, project_campaign_id = null, project_owner_id = null, project_name = null, external_reference = null, additional_external_reference = null, form_configuration } = {}) {
    this.id = id
    this.name = name
    this.category = category
    this.end_date = end_date
    this.start_date = start_date
    this.contributions_count = contributions_count
    this.contributors_count = contributors_count
    this.actions = new Actions(Object.values(actions))
    this.payment_frequency = payment_frequency
    this.payment_profile = payment_profile
    this.status = status
    this.slug = slug
    this.goal_amount = goal_amount
    this.fixed_goal_amount = fixed_goal_amount
    this.current_amount = current_amount
    this.transactions = new Transactions(transactions)
    this.settings = new Settings(settings)
    this.default_values = default_values
    this.target_id = target_id
    this.target_type = target_type
    this.limit_of_members = limit_of_members
    this.form_provider = form_provider
    this.success_payment_page_url = success_payment_page_url
    this.project_campaign_id = project_campaign_id
    this.project_owner_id = project_owner_id
    this.project_name = project_name
    this.external_reference = external_reference
    this.additional_external_reference = additional_external_reference
    this.form_configuration = form_configuration
  }

  get form_configuration () {
    return this._form_configuration
  }

  set form_configuration (form_configuration) {
    if (this.payment_provider === 'twikey') return (this._form_configuration = new TwikeyFormConfiguration(form_configuration))
    return (this._form_configuration = new FormConfiguration(form_configuration))
  }

  get payment_provider () {
    return _get(this, 'payment_profile.payment_provider')
  }

  get is_draft () {
    return this.status === 'draft'
  }

  get is_authentication_required () {
    return this.settings.restrictions.authentication_required === true
  }

  get is_one_time_completion () {
    return this.settings.restrictions.one_time_completion === true
  }

  get is_ended () {
    return this.end_date && isPast(new Date(this.end_date))
  }

  get is_started () {
    return !this.start_date || (this.start_date && isPast(new Date(this.start_date)))
  }

  get style () {
    return this.form_configuration.style
  }

  get steps () {
    return this.form_configuration.steps
  }

  get blocks () {
    return new Blocks(_flattenDeep(this.steps.map((step) => step.sections.blocks)))
  }

  get validator () {
    if (this.is_ended) return { title: 'procedure.form_has_ended_title', text: 'procedure.form_has_ended_text', type: 'info' }
    if (!this.is_started) return { title: 'procedure.form_not_started_yet_title', text: 'procedure.form_not_started_yet_text', type: 'info' }

    if (!this.steps.length) return { code: '#001101' }
    if (!this.steps.success) return { code: '#001102' }
    if (this.actions.transaction && !this.blocks.contains('actions_data.transaction.payment_method')) return { code: '#001103' }
    if (!this.actions.transaction && (this.actions.fee || this.actions.order || this.blocks.contains('actions_data.transaction.amount'))) return { code: '#001104' }
    if (!this.actions.order && this.blocks.product_ids.length) return { code: '#001105' }
    if (this.actions.create_invite && !this.actions.create_invite.is_optional && !this.blocks.contains('actions_data.create_invite.emails')) return { code: '#001106' }
    if (!this.actions.create_invite && this.blocks.contains('actions_data.create_invite.emails')) return { code: '#001107' }
    if (this.category === 'project-invite' && !this.actions.accept_invite) return { code: '#001108' }
    if (this.actions.accept_invite && !this.blocks.contains('actions_data.accept_invite.invite_token')) return { code: '#001109' }
    if (this.category === 'create-project' && !this.actions.project) return { code: '#001110' }
    if (this.actions.transaction && !Object.entries(this.payment_profile).length) return { code: '#001111' }
    if (this.category === 'donation' && !this.actions.recaptcha && (!this.actions.transaction || this.actions.transaction?.is_optional)) return { code: '#001112' }

    return false
  }

  get frequencies () {
    return this.blocks.transaction_frequency?.attributes.options?.map(({ value }) => value) || ['one_time']
  }

  get to_tamaro_automation () {
    if (this.target_type === 'Project') {
      return {
        koa_project_id: this.target_id,
        koa_project_name: this.project_name,
        koa_project_campaign_id: this.project_campaign_id,
        koa_project_owner_id: this.project_owner_id,
        koa_goal_amount_subunit: this.goal_amount
      }
    } else if (this.target_type === 'Campaign') {
      return {
        koa_campaign_id: this.target_id
      }
    }

    return {}
  }
}
