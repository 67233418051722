import { computed, toValue } from 'vue'
import { storeToRefs } from 'pinia'
import { useCartStore } from '@/stores/CartStore'
import { useProcedureStore } from '@/stores/ProcedureStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useTimeout } from '@/composables/app/useTimeout'
import { useProcedure, useProcedureExecution, useProcedureNavigation } from '@/composables/modules/procedures'
import { useTracking } from '@/vendors/tracking'
import { useRouter, useRoute } from 'vue-router'
import { useTamaro } from '@/vendors/integrations/tamaro'
import { get as _get, set as _set } from 'lodash'

const PENDING_STATUS = ['#transaction', '#project', '#order', '#fee' , '#create_invite', '#accept_invite', '#recaptcha', '#sendparticipationemail']
const ERROR_STATUTS = ['authorization_failed', 'charge_creation_failed', 'charge_failed', 'refunded', 'after_action_errors', 'archived']

export function useProcedureTransaction ({ procedure, procedure_execution }) {
  const { query } = useRoute()
  const { replace } = useRouter()
  const tracking = useTracking()
  const { getCart, patchFormAmount } = useCartStore()
  const { fetchProcedureTransactionCheck } = useProcedureStore()
  const { loader, error } = storeToRefs(useProcedureStore())
  const { postModalNotification } = useNotificationStore()
  const { id: procedureId, paymentProvider } = useProcedure({ procedure })
  const { handleFetchProcedureExecution } = useProcedureExecution({ procedure, procedure_execution })
  const { nextRedirection } = useProcedureNavigation({ procedure, procedure_execution })
  const { isStarted, startTimeout, isTimeout } = useTimeout({ delay: 60000 })
  const { send: sendToTamaro } = useTamaro()

  const { order } = getCart({ id: procedureId })

  const id = computed(() => {
    if (query.client_secret && query.source) return query.source
    if (query.payment_intent_client_secret) return query.payment_intent
    if (query.transaction_id) return query.transaction_id

    return null
  })

  const hasTransaction = computed(() => _get(toValue(procedure_execution), 'has_transaction'))

  const transactionId = computed(() => _get(toValue(procedure_execution), 'transaction_id'))

  const transactionStatus = computed(() => _get(toValue(procedure_execution), 'transaction_status'))

  const transactionRedirectUrl = computed(() => _get(toValue(procedure_execution), 'transaction_redirect_url'))

  const transactionIntentId = computed(() => _get(toValue(procedure_execution), 'transaction_intent_id'))

  const formAmount = computed({
    get: () => parseInt(_get(toValue(procedure_execution), 'actions_data.transaction.form_amount', 0)),
    set: amount => {
      _set(toValue(procedure_execution), 'actions_data.transaction.form_amount', amount)

      patchFormAmount({ id: procedureId, form_amount: amount })
    }
  })

  const frequency = computed({
    get: () => _get(toValue(procedure_execution), 'actions_data.transaction.frequency', 'one_time'),
    set: frequency => _set(toValue(procedure_execution), 'actions_data.transaction.frequency', toValue(frequency))
  })

  const amount = computed({
    get: () => _get(toValue(procedure_execution), 'actions_data.transaction.amount', 0),
    set: amount => _set(toValue(procedure_execution), 'actions_data.transaction.amount', toValue(amount))
  })

  const paymentMethod = computed({
    get: () => _get(toValue(procedure_execution), 'actions_data.transaction.payment_method'),
    set: paymen_method => _set(toValue(procedure_execution), 'actions_data.transaction.payment_method', toValue(paymen_method))
  })

  const paymentMethodInformations = computed({
    get: () => _get(toValue(procedure_execution), 'actions_data.transaction.payment_method_informations', {}),
    set: informations => _set(toValue(procedure_execution), 'actions_data.transaction.payment_method_informations', toValue(informations))
  })

  const setCurrency = currency => _set(toValue(procedure_execution), 'actions_data.transaction.currency', currency)
  const setRedirectUrl = url => _set(toValue(procedure_execution), 'actions_data.transaction.redirect_url', url)

  const handlePayingProcedure = () => {
    tracking.procedure.checkout({ procedure: toValue(procedure), procedure_execution: toValue(procedure_execution), order: toValue(order) })

    if (paymentMethod.value === 'test_payment') return handleCheckTransaction(transactionId.value)
    if (paymentProvider.value === 'tamaro') return sendToTamaro(procedure_execution.value.to_tamaro)
    if (['stripe', 'stripe_standard_connect'].includes(paymentProvider.value) && paymentMethod.value === 'three_d_secure' && !transactionRedirectUrl.value) return handleCheckTransaction(transactionIntentId.value)

    window.location.href = transactionRedirectUrl.value
  }

  const handleCheckTransaction = transaction_id => {
    if (!toValue(transaction_id)) return

    loader.value = true
    error.value = null

    if (!isStarted.value) startTimeout()

    fetchProcedureTransactionCheck({ procedure_id: procedureId.value, transaction_id: toValue(transaction_id) })
      .then(({ status, procedure_execution_id, procedure_execution_status }) => {
        if (PENDING_STATUS.includes(procedure_execution_status) && !isTimeout()) return setTimeout(() => handleCheckTransaction(transaction_id), 1000)

        if (ERROR_STATUTS.includes(status)) replace({ query: {} }).then(() => error.value = 'errors.payment_failed')

        else if (['error'].includes(procedure_execution_status) || !procedure_execution_id) return postModalNotification({ title: 'errors.form_execution_validation_failed_title', text: 'errors.form_execution_validation_failed_text', code: '#001305', error: 'Procedure execution\'s status is "error"', state: { procedure_id: procedureId.value, execution_id: toValue(execution_id) }, close: nextRedirection })

        else handleFetchProcedureExecution(procedure_execution_id)

        loader.value = false
      })
      .catch(() => postModalNotification({ title: 'errors.form_transaction_validation_failed_title', text: 'errors.form_transaction_validation_failed_text', code: '#001301', error: 'GET Transaction check failed', state: { transaction_id: toValue(transaction_id) }, close: nextRedirection }))
  }

  return {
    id,
    hasTransaction,
    transactionId,
    transactionStatus,
    transactionRedirectUrl,
    transactionIntentId,
    formAmount,
    frequency,
    amount,
    paymentMethod,
    paymentMethodInformations,
    setCurrency,
    setRedirectUrl,
    handlePayingProcedure,
    handleCheckTransaction
  }
}