<template>
  <div v-if="isShowed('cover')">
    <app-video-embed
      v-if="project.video_url"
      :src="project.video_url"
    />

    <figure
      class="cover"
      v-else
    >
      <img
        :src="cover"
        :alt="project.name"
        class="image"
      >

      <router-link
        class="btn"
        :to="{ name: 'ProjectSettings', params: { projectSlug: project.slug }, query: { tab: 'general' } }"
        v-if="isAuthorized"
      >
        <fa-icon
          class="margin__r--6"
          :icon="['fal', 'camera']"
        />

        {{ t('literal.edit_cover') }}
      </router-link>
    </figure>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { mapGetters } from '@/store/map-state'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { getImagePath } from '@/utils/utils'
import { get as _get } from 'lodash'
import { useRoles } from '@/composables/app/useRoles'

const AppVideoEmbed = defineAsyncComponent(() => import('&/atoms/AppVideoEmbed'))

const props = defineProps({ block: { type: Object, required: true } })

const { t } = useI18n()
const { user: authUser } = mapGetters('auth')
const { objects, isShowed } = useBlock(props.block)
const { isSuperAdmin, isKoalect } = useRoles()

const project = computed(() => _get(objects.value, 'project'))
const isAuthorized = computed(() => project.value.isAuthorized(authUser.value) || isSuperAdmin.value || isKoalect.value)
const cover = computed(() => _get(project.value, 'image.medium.url') || _get(project.value, 'campaign.image.medium.url') || getImagePath('placeholders/project-card-cover.jpg'))
</script>

<style lang="scss" scoped>
.cover {
  position: relative;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  background-size: 100% auto;

  @include aspect-ratio(3, 2);
}

.image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.btn {
  position: absolute;
  top: $margin__base;
  right: $margin__base;
  background-color: $white;
  padding: calc($padding__base / 4) $padding__base;
  font-size: em(14px);
  color: $dg;

  @include mq(md) {
    opacity: .4;
  }

  &:hover {
    background: $white;
    opacity: 1;
  }
}
</style>