<template>
  <h2
    class="title"
    v-if="isShowed('title')"
  >
    <template v-if="isAuthorized && is_editing">
      <input-textarea
        class="textarea"
        v-model="name"
        :maxlength="max_length"
      />

      <div class="actions d-flex align-items-center justify-content-end margin__l--12">
        <app-spinner
          size="3"
          v-if="loader"
        />

        <template v-else>
          <button
            class="action-btn link__color--success link--naked bg__color--white"
            :class="{ 'btn--disabled': loader }"
            :aria-label="t('literal.save')"
            :disabled="name.length <= 0"
            @click.prevent="handleClickSave"
          >
            <fa-icon :icon="['fal', 'check']" />
          </button>

          <button
            class="action-btn link__color--danger link--naked bg__color--white margin__l--12"
            :class="{ 'btn--disabled': loader }"
            :aria-label="t('literal.cancel')"
            @click.prevent="is_editing = false"
          >
            <fa-icon :icon="['fal', 'times']" />
          </button>

          <span
            class="remaining-characters"
            :class="{ 'remaining-characters--error': remainingCount <= 0 }"
          >
            {{ remainingCount }}
          </span>
        </template>
      </div>
    </template>

    <template v-else>
      {{ project.name }}

      <div
        class="actions margin__l--24"
        v-if="isAuthorized"
      >
        <button
          class="action-btn link__color--mg link--naked bg__color--white"
          :aria-label="t('literal.edit_project_title')"
          @click.prevent="handleClickEdit"
        >
          <fa-icon
            size="sm"
            :icon="['fal', 'edit']"
            fixed-width
          />
        </button>
      </div>
    </template>
  </h2>
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useProjectStore } from '@/stores/ProjectStore'
import { mapGetters } from '@/store/map-state'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { useRoles } from '@/composables/app/useRoles'
import { get as _get } from 'lodash'

const InputTextarea = defineAsyncComponent(() => import('&/atoms/InputTextarea'))
const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))

const props = defineProps({ block: { type: Object, required: true } })

const route = useRoute()
const { t } = useI18n()
const { getProject, patchProject, $resetStore } = useProjectStore()
const { user: authUser } = mapGetters('auth')
const { objects, isShowed } = useBlock(props.block)
const { isSuperAdmin, isKoalect } = useRoles()

const max_length = 50

const is_editing = ref(false)

const { clonedProject, loader } = getProject({ id: route.params.projectSlug })

const project = computed(() => _get(objects.value, 'project'))
const isAuthorized = computed(() => project.value.isAuthorized(authUser.value) || isSuperAdmin.value || isKoalect.value)
const remainingCount = computed(() => max_length - name.value.length)

const name = computed({
  get: () => clonedProject.value.name,
  set: name => clonedProject.value.name = name
})

const handleClickEdit = () => {
  name.value = project.value.name
  is_editing.value = true
}

const handleClickSave = () => {
  patchProject({ id: route.params.projectSlug, params: { name: name.value } })
    .then(() => {
      is_editing.value = false

      $resetStore('projects')
    })
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  display: flex;
  align-items: flex-start;
  font-weight: bold;
  font-size: rem(20px);

  @include mq(md) {
    font-size: rem(42px);
  }
}

:deep(.textarea) {
  font-weight: bold;
  font-size: rem(20px);
  background-color: transparent;
  resize: none;
  padding: 0;
  line-height: 1.5;
  border: 0;
  flex: 1 1 auto;
  font-family: $font-family--secondary;

  @include mq(md) {
    font-size: rem(42px);
  }

  &.input {
    min-width: 0;
  }
}

.actions {
  position: relative;
  min-height: 30px;

  @include mq(md) {
    margin-top: $margin__base * 1.5;
  }
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  font-size: rem(16px);
}

.remaining-characters {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  font-size: rem(12px);
  text-align: right;
  color: rgba($dg, .3);
  padding-top: $padding__base;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: rgba($dg, .1);
    margin-bottom: calc($margin__base / 2);
  }

  &--error {
    color: $color--danger;
  }
}
</style>