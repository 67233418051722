<template>
  <div>
    <div
      class="d-flex justify-content-center"
      v-if="is_tamaro_loading"
    >
      <app-spinner size="3" />
    </div>

    <div v-show="!is_tamaro_loading">
      <div
        id="tamaro"
        ref="tamaro"
        :class="{'input--errors': hasErrors}"
      />

      <div
        class="input--errors margin__t--6"
        v-if="v?.$error"
      >
        <p
          class="input--error"
          v-if="v?.required && v.required.$invalid"
        >
          {{ t('errors.input_radio_required') }}
        </p>
      </div>
    </div>

    <payment-provider-tamaro-fields
      class="margin__t--12"
      :fields="requiredFields"
      v-if="requiredFields.length"
    />
  </div>
</template>

<script setup>
import { ref, computed, inject, defineAsyncComponent, watch, onBeforeUnmount } from 'vue'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@/stores/ClientStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useSecureStore } from '@/stores/SecureStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useCurrencyStore } from '@/stores/CurrencyStore'
import { useProcedureStore } from '@/stores/ProcedureStore'
import { useProcedureExecutionStore } from '@/stores/ProcedureExecutionStore'
import { useProcedure, useProcedureTransaction, useProcedureNavigation, useProcedureBlockValidator } from '@/composables/modules/procedures'
import { useTamaro } from '@/vendors/integrations/tamaro'
import { useI18n } from '@/vendors/i18n'
import { default_config, available_payment_types, available_fields } from '@/configurations/financials/tamaro'
import { whenever, scrollTo } from '@/utils/utils'
import { externalValidation } from '@/utils/validators'
import { sameAs } from '@vuelidate/validators'
import { get as _get, set as _set } from 'lodash'

const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))
const PaymentProviderTamaroFields = defineAsyncComponent(() => import('&/modules/procedures/fragments/transaction/PaymentProviderTamaroFields'))

const props = defineProps({
  paymentMethods: { type: Array, default: () => [] },
  v: Object
})

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')
const is_loading = inject('is_loading')
const errors = inject('errors')
const external_validations = inject('external_validations')

const { is_loading: is_tamaro_loading, requiredFields, validationErrors, render, destroy, setAmount, setAmounts, setPaymentFormData, afterRender, paymentMethodChanged, paymentComplete, paymentError } = useTamaro()
const { t } = useI18n()
const { getClient } = useClientStore()
const { fetchProcedureSecureToken } = useSecureStore()
const { postModalNotification } = useNotificationStore()
const { getDocumentLanguage } = useLanguageStore()
const { currency, available_currencies } = storeToRefs(useCurrencyStore())
const { loader: procedureExecutionLoader } = storeToRefs(useProcedureExecutionStore())
const { loader: procedureLoader, error } = storeToRefs(useProcedureStore())
const { transactionId, amount, frequency, paymentMethod, handleCheckTransaction } = useProcedureTransaction({ procedure, procedure_execution })
const { slug: procedureSlug, externalReference, additionalExternalReference, targetId, targetType } = useProcedure({ procedure })
const { nextRedirection } = useProcedureNavigation({ procedure, procedure_execution })
useProcedureBlockValidator({ validations: { sameAs: sameAs(undefined) }, state: validationErrors })

const { client } = getClient()

const tamaro = ref()
const transactionFormAmountBlock = computed(() => procedure.value?.blocks.transaction_form_amount)

const amounts = computed(() => _get(transactionFormAmountBlock.value, `attributes.${frequency.value}.amounts`, []).map(amount => amount.value / 100))
const frequencies = computed(() => procedure.value?.frequencies.map(frequency => _get(available_payment_types, frequency)).filter(v => v))
const dependenciesAreReady = computed(() => tamaro.value && !is_loading.value)
const hasErrors = computed(() => Object.keys(validationErrors.value || {}).some(error => !error.includes('stored_')))

const config = computed(() => ({
  ...default_config,
  defaultAmount: amount.value,
  currencies: available_currencies.value.map(currency => currency.toLowerCase()),
  amounts: amounts.value,
  language: getDocumentLanguage,
  paymentMethods: props.paymentMethods.map(paymentMethod => paymentMethod.value),
  defaultPaymentMethod: paymentMethod.value !== 'test_payment' ? paymentMethod.value : null,
  defaultCurrency: currency.value.toLowerCase(),
  paymentTypes: frequencies.value,
  paymentFormPrefill: {
    ...procedure_execution.value.to_tamaro,
    currency: currency.value.toLowerCase(),
    raisenow_parameters: {
      fundraising_automation: {
        attachment: JSON.stringify(procedure.value.to_tamaro_automation)
      }
    }
  },
  [process.env.VUE_APP_TAMARO_ENV === 'prod' ? 'epms' : 'epmsStage']: client.value.tamaro,
  testMode: procedure.value.is_draft
}))

const handleProcedureExecutionChange = () => {
  setAmounts(amounts.value)
  setAmount(amount.value)
  setPaymentFormData(procedure_execution.value.to_tamaro)
}

whenever(dependenciesAreReady, () => {
  render('#tamaro', config.value)
    .then(() => {
      afterRender(() => {
        if (errors.value) setTimeout(() => scrollTo('#errors'))
      })

      handleProcedureExecutionChange()

      setPaymentFormData({
        stored_campaign_id: externalReference.value,
        stored_campaign_subid: additionalExternalReference.value,
        stored_koa_target_id: targetId.value,
        stored_koa_target_type: targetType.value
      })

      paymentMethodChanged(payment_method => paymentMethod.value = payment_method)

      paymentComplete(status => {
        if (['succeeded', 'active'].includes(status)) {
          handleCheckTransaction(transactionId.value)
        } else {
          error.value = 'errors.payment_failed'
          procedureLoader.value = false
        }

        procedureExecutionLoader.value = false

        fetchProcedureSecureToken({ id: procedureSlug })
      })

      paymentError(code => postModalNotification({ title: `errors.tamaro_${code.replace('.', '_')}_title`, text: `errors.tamaro_${code.replace('.', '_')}_text`, code: '#001504', error: `POST Tamaro payments - ${code}`, close: nextRedirection }))
    })
    .catch(() => postModalNotification({ title: 'errors.an_error_occurred', code: '#001503', error: 'Tamaro failed to load', close: nextRedirection }))
})

watch(() => procedure_execution, () => handleProcedureExecutionChange(), { deep: true })

watch(validationErrors, () => {
  external_validations.value = Object.keys(validationErrors.value || {})
    .filter(key => _get(available_fields, key))
    .reduce((validations, key) => _set(validations, _get(available_fields, `${key}.model`), validationErrors.value[key].map(({ code, data = {} }) => externalValidation(`errors.tamaro_${code}`, data)).find(v => v)), {})
}, { deep: true })

onBeforeUnmount(() => destroy())
</script>

<style lang="scss" scoped>
#tamaro.input--errors {
  padding: 0;
  background: none;
}
</style>