<template>
  <li v-if="isAvailable">
    <span
      class="link d-block link__color--lg-lg link--naked cursor--not-allowed"
      :class="{ 'router-link-active': path === '' }"
      v-if="isPreview && !isTab"
    >
      {{ text }}
    </span>

    <router-link
      :to="to"
      custom
      v-slot="{ href, navigate, isActive }"
      v-else
    >
      <a
        class="link d-block link__color--lg-dg link--naked"
        :class="{ 'router-link-active': isTab ? isTabActive : isActive }"
        :href="href"
        @click="navigate"
      >
        {{ text }}
      </a>
    </router-link>
  </li>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useRouter, useRoute } from 'vue-router'
import { useProjectStore } from '@/stores/ProjectStore'
import { useBlock } from '@/composables/modules/templates'
import { useQrcode } from '@/composables/modules/projects/useQrcode'
import { getStringifyQuery } from '@/utils/utils'
import { get as _get, isEqual as _isEqual } from 'lodash'

const props = defineProps({ block: { type: Object, required: true } })

const { resolve } = useRouter()
const route = useRoute()
const { locale, translate } = useI18n()
const { attributes, isPreview, isShowed, getText } = useBlock(props.block)
const { getProject } = useProjectStore()

const { project } = getProject({ id: route.params.projectSlug })
const { isQrcodeAvailable } = useQrcode({ project })

const { projectSlug, templateId } = route.params

const path = _get(attributes, 'path')
const query = _get(attributes, 'query', {})

const text = computed(() => translate(getText('navigation')))
const isTab = computed(() => 'tab' in query)
const isTabActive = computed(() => _isEqual(query, route.query) )

const to = computed(() => {
  if (isPreview.value && Object.keys(query).length) return { name: 'Preview', params: { category: 'project', templateId }, query }

  return resolve(`/${locale}/project/${projectSlug}/${path}${getStringifyQuery({ query })}`)
})

const isAvailable = computed(() => {
  if (path === 'qr-codes' && !isQrcodeAvailable.value && !isPreview.value) return false

  return isShowed('navigation') && text.value
})
</script>

<style lang="scss" scoped>
.link {
  padding: 12px;
  border-bottom: 2px solid transparent;
  margin: 0;
  white-space: nowrap;

  @include mq(sm) {
    border-width: 1px;
  }

  &.router-link-active {
    color: $dg;
    font-weight: bold;
    border-color: $color--primary;
  }
}
</style>